"use client";

import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Fab from "@mui/material/Fab";
import { Box, Grow, Stack, ClickAwayListener, useMediaQuery, alpha, useTheme, Tooltip, Zoom, Typography, Fade } from "@mui/material";
import Chat from "./Chat";
import { ChatContext } from "./context";
import { useWidgetSettingsContext } from "./settings";
import chroma from "chroma-js";
import Iconify from "./components/iconify";
import Image from "./components/image";

export default function WidgetChat(): JSX.Element {
  const { setChatOpen, editMode, isHome } = useContext(ChatContext);
  const [chatOpen, setChatLocalOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const elementRef = useRef(null);
  const theme = useTheme();
  const settings = useWidgetSettingsContext();

  const [showTooltip, setShowTooltip] = useState<boolean>(true);

  const fabBoxStyle = useMemo(
    () => ({
      position: "fixed",
      zIndex: 999,
      ...(settings.position === "left"
        ? {
          left: settings.sideSpacing,
        }
        : {
          right: settings.sideSpacing,
        }),

      bottom: settings.bottomSpacing,
      "& .MuiFab-root": {
        backgroundColor: settings.laucherBgColor!,
        " &:hover, &:active": {
          backgroundColor: alpha(settings.laucherBgColor!, 0.8),
        },
      },
    }),
    [theme, settings]
  );

  const chatBoxStyle = useMemo(
    () => ({
      position: "fixed",
      bottom: {
        xs: 0,
        sm: 76 + settings.bottomSpacing,
        md: 86 + settings.bottomSpacing,
      },

      ...(settings.position === "left"
        ? {
          left: {
            xs: 0,
            sm: settings.sideSpacing,
          },
        }
        : {
          right: {
            xs: 0,
            sm: settings.sideSpacing,
          },
        }),

      minWidth: {
        sm: 300,
      },
      maxWidth: 600,
      width: {
        xs: 1,
        sm: 0.4,
        md: 0.3,
      },
    }),
    [settings]
  );

  const handleClickAway = () => {
    if (!editMode && !isHome) {
      setChatLocalOpen(false);
      setChatOpen(false);
    }
  };

  const handleFabClick = () => {
    setChatLocalOpen(prev => !prev);
    setChatOpen(prev => !prev);
    setShowTooltip(false);
  };

  useEffect(() => {
    // When the widget is opened
    if (chatOpen) {
      window.parent.postMessage({ widgetState: "open", height: "700px", width: "350px" }, "*");
      if (window.innerWidth < 600) {
        document.documentElement.style.cssText = "overflow: hidden !important";
      }
    } else {
      window.parent.postMessage({ widgetState: "closed", height: "56px", width: "56px" }, "*");
      document.documentElement.style.cssText = "overflow: auto !important";
    }

    // When the widget is closed
  }, [chatOpen]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <Stack id="chat-widget-container" sx={{ zIndex: chatOpen ? 1200 : -1, ...chatBoxStyle }}>
          <Grow
            in={chatOpen}
            style={{
              transformOrigin: "bottom right",
            }}
          >
            <Box sx={{ width: 1 }}>
              <Chat />
            </Box>
          </Grow>
        </Stack>
        <Box id="chat-widget-fab" sx={fabBoxStyle} ref={elementRef}>
          {isMobile && !chatOpen && (
            <Grow in style={{ transformOrigin: "top left" }}>
              <Tooltip
                open={showTooltip}
                title={"Hi👋, Chat with us!"}
                placement={settings.position === "left" ? "right" : "left"}
                TransitionComponent={Zoom}
                arrow
                onClose={() => setShowTooltip(false)}
              >
                <Fab
                  onClick={handleFabClick}
                  sx={{
                    p: 1,
                  }}
                >
                  {chatOpen ? (
                    <Iconify
                      icon={"material-symbols:close-rounded"}
                      sx={{
                        color:
                          chroma(settings.laucherBgColor || "white").luminance() > 0.5
                            ? "#000"
                            : "white",
                      }}
                    />
                  ) : (settings?.launcherIcon as any)?.preview ? (
                    <Image
                      sx={{
                        borderRadius: "100%",
                        aspectRatio: 1,
                        width: 1,
                      }}
                      src={(settings?.launcherIcon as any)?.preview}
                    />
                  ) : (
                    <Iconify
                      icon={"ic:round-chat"}
                      sx={{
                        color:
                          chroma(settings.laucherBgColor || "white").luminance() > 0.5
                            ? "#000"
                            : "#fff",
                      }}
                    />
                  )}
                </Fab>
              </Tooltip>
            </Grow>
          )}

          {!isMobile && (
            <Tooltip
              open={showTooltip}
              title={
                <Box sx={{ textAlign: "center", padding: 1 }}>
                  <Typography variant="body2" sx={{
                    fontWeight: 500,
                    color: "black",
                    maxWidth: 200,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}>
                    {"Hi👋, Chat with us!"}
                  </Typography>
                </Box>
              }
              placement="right"
              TransitionComponent={Fade}
              TransitionProps={{
                timeout: 300,
              }}
              arrow
              onClose={() => setShowTooltip(false)}
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: '#FFFFFF',
                    borderRadius: "10px",
                    border: '1px solid #C4CDD5',
                    padding: "10px 12px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
                    transition: "transform 0.3s ease, opacity 0.3s ease",
                    animation: "float 3s ease-in-out infinite",
                    "@keyframes float": {
                      "0%": { transform: "translateY(0px)" },
                      "50%": { transform: "translateY(-5px)" },
                      "100%": { transform: "translateY(0px)" },
                    },
                  },
                },
                arrow: {
                  sx: {
                    '&::before': {
                      border: '1px solid #C4CDD5',
                      backgroundColor: '#FFFFFF',
                    },
                  },
                },
              }}
            >
              <Fab
                onClick={handleFabClick}
                sx={{
                  p: 1,
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
              >
                {chatOpen ? (
                  <Iconify
                    icon={"material-symbols:close-rounded"}
                    sx={{
                      color:
                        chroma(settings.laucherBgColor || "white").luminance() > 0.5
                          ? "#000"
                          : "#fff",
                    }}
                  />
                ) : (settings?.launcherIcon as any)?.preview ? (
                  <Image
                    sx={{
                      borderRadius: "100%",
                      aspectRatio: 1,
                      width: 1,
                    }}
                    src={(settings?.launcherIcon as any)?.preview}
                  />
                ) : (
                  <Iconify
                    icon={"ic:round-chat"}
                    sx={{
                      color:
                        chroma(settings.laucherBgColor || "white").luminance() > 0.5
                          ? "#000"
                          : "#fff",
                    }}
                  />
                )}
              </Fab>
            </Tooltip>
          )}
        </Box>
      </Box>
    </ClickAwayListener>
  );
}